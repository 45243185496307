.legal {
    font-size: 12pt;
    width: 50%;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .legal td{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .checkbox {
    margin: 0px;
    display: inline;
    padding: 5px;
    height: 25px;
    width: 25px;
  }
  
  .email {
    margin: 0px;
    display: inline;
    padding: 5px;
    height: 25px;
    width: 250px;
  }
  
  .legal-tc {
    font-size: 12pt;
  }
  
  .resultsTableWrapper {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .error {
    font-size: 14pt;
    font-weight: 600;
    text-align: center;
  }