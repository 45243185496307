/* Main settings */
body {
  margin: 0px;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Styles of the background container */
div .App .bg_box {
  max-width: 1000px;
  color: #212121;
  width: 95%;
  min-height: 150px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
  background: linear-gradient(
    0deg,
    rgba(224, 223, 219, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: left;
  min-height: 250px;
}

/* Styling of questionnaire */
h1 {
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 38pt;
  margin: 25px 30px 10px 30px;
  padding-bottom: 15px;
  background-image: url("/public/marker_green.png");
  background-size: 250px;
  background-repeat: no-repeat;
  background-position: 0px 48px;
}

table {
  text-align: center;
  width: 100%;
}

th {
  width: 10%;
}

.paragraph,
.input,
.input-ticket,
.question {
  font-size: 15pt;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.feedbackWrapper {
  font-size: 15pt;
  size: 80%;
  margin-bottom: 0px;
}

.finish {
  width: 70%;
  margin: auto;
  margin-bottom: 10px;
}

.question {
  padding-top: 15px;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.links {
  width: 35%;
  float: right;
  font-size: 13pt;
  font-weight: 600;
  border-color: #e3e3e3;
  border: 1px;
  border-right: 0px;
  border-style: dotted;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 30px;
  margin-bottom: 15px;
  margin-top: 70px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
}
.image-box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  clear: both;
}

a {
  color: #006345;
  text-decoration: none;
}

a:hover {
  color: #00b064;
  text-decoration: dotted;
  font-weight: 600;
}

ul {
  text-align: left;
  list-style: disc;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin: 0px;
}

li {
  padding: 2px;
}

.input {
  text-align: center;
}

.feedbackWrapper {
  width: 70%;
  text-align: center;
  margin: auto;
  font-weight: 600;
  color: #296147;
}

span {
  display: block;
}

.note {
  padding-top: 0;
  font-weight: 400;
  font-size: 13pt;
  color: #757575;
}

.input img {
  text-align: center;
  height: 20px;
}

/* Styling of the golden ticket */
.input-ticket .ticket {
  width: 150px;
  height: 60px;
  display: table-cell;
  display: inline;
  text-align: center;
  margin-left: 60px;
  margin-top: 50px;
  margin-bottom: 50px;
  border: none;
  font-size: 25pt;
}

div .input-ticket {
  background-image: url("../public/ticket-web-flat.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-bottom: 20px;
  width: fit-content;
  text-align: center;
  margin-top: 25px;
}

.input-ticket .success {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.ticket-text {
  color: #fff;
  padding-top: 7px;
  margin-bottom: 0px;
}

.input-ticket .btn-ticket {
  height: 60px;
  display: table-cell;
  position: relative;
  top: 45px;
  background-color: #c68400;
  border: none;
  border-radius: 5px;
  font-size: 12pt;
  transition-duration: 0.4s;
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;
  border: none;
  border-radius: 10px;
  margin: 5px 50px 0px 10px;
  padding: 5px 15px 5px 15px;
}

.input-ticket .btn-ticket:hover {
  padding: 5px 15px 10px 15px;
}

.input-ticket .btn-ticket .refresh {
  width: 20px;
  position: relative;
  top: 5px;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.input-ticket .btn-ticket:hover .refresh {
  transform: rotate(360deg) scale(1.2);
}

/* Buttons and associated animation transforms */
.question {
  padding-top: 15px;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.note {
  padding-top: 0;
  font-weight: 400;
  font-size: 13pt;
  color: #757575;
}

.input img {
  text-align: center;
  height: 20px;
}

.next-btn {
  text-align: center;
  padding-bottom: 10px;
}

.btn {
  transition-duration: 0.4s;
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;
  border: none;
  border-radius: 10px;
  color: #fff;
  background-color: #296147;
  font-size: 20pt;
  margin: 0px 10px 10px 10px;
  padding: 10px 30px 10px 30px;
}

.pointsBtn {
  padding: 5px 15px 5px 15px;
  margin: 0;
  display: block;
}

.btn:hover {
  background-color: #4fae6b; /* Green */
  color: white;
  padding: 10px 40px 10px 40px;
}

.btn-disabled:hover {
  background-color: #8c8c8c;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  padding: 10px 30px 10px 30px;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.btn-disabled {
  background-color: #8c8c8c;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Styling of input types */
input[type="range"],
.range {
  -webkit-appearance: none;
  appearance: 1;
  width: 90%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb,
.range::-webkit-slider-thumb,
.range {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

input[type=range]::-ms-track,
.range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

.time,
.date,
.textarea,
.text,
.ticket {
  -webkit-appearance: none;
  appearance: initial;
  font-family: "Inter", sans-serif;
  font-weight: 550;
  font-size: 18pt;
  border-radius: 5px;
  background: #fff;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-top: 20px;
  display: block;
}

.time,
.date {
  height: 55px;
  text-align: center;
}

.time {
  width: 130px;
}

.date {
  width: 200px;
}

.time:hover,
.date:hover,
.textarea:hover,
.text:hover,
select:hover,
.ticket:hover,
.range:hover {
  opacity: 1;
}

.textarea {
  width: 90%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.text {
  text-align: center;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.requestQuestions {
  margin: 0;
  padding: 0;
}

input,
iframe,
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  border: 0px;
}

.youtube {
  --height: 450px;
  height: var(--height);
  width: calc(var(--height) * (16 / 9));
  max-width: 90%;
}

select {
  width: 90%;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 550;
  font-size: 18pt;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background: #fff;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

img {
  width: fit-content;
  max-height: 500px;
  max-width: 90%;
  width: auto;
  padding: 0 0 0 0;
}

.img-full,
iframe {
  margin-top: 30px;
  box-shadow: 8px 20px 2px rgba(0, 0, 0, 0.03);
}

.img-txt {
  width: 60%;
  margin: auto;
  font-size: 11pt;
  font-style: italic;
  text-align: center;
  color: #757575;
  margin-top: 10px;
}

hr {
  width: 80%;
  border-style: dashed;
  color: #bababa;
}

.pointsForm {
  margin: 5% 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.feedbackWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading animation */

.modal {
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
}

.loading-dots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-dots div {
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-dots div:nth-child(1) {
  left: 8px;
  animation: loading-dots1 0.6s infinite;
}
.loading-dots div:nth-child(2) {
  left: 8px;
  animation: loading-dots2 0.6s infinite;
}
.loading-dots div:nth-child(3) {
  left: 32px;
  animation: loading-dots2 0.6s infinite;
}
.loading-dots div:nth-child(4) {
  left: 56px;
  animation: loading-dots3 0.6s infinite;
}

.afterSubmitButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loadingState {
  text-align: center;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00b064;
  opacity: 90%;
  padding-bottom: 20px;
  height: 50px;
  width: 200px;
  line-height: 50px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
}

.block {
  display: block;
}

.row {
  width: 80%;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 1rem;
  align-items: center;
}

.column {
  display: flex;
  justify-content: flex-start;
  min-height: 70px;
  flex-direction: column;
  align-items: center;
}

.grey {
  color: grey;
  opacity: 50%;
}

.number {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.icon-true {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.icon-false {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.icont-inEvaluation {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

@keyframes loading-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
